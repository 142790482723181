<template>
  <div ref="scrollIndicator" class="scroll-indicator" @click="scrollDown">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <!-- Font Awesome Pro 5.15.1 by @fontawesome - https://fontawesome.com License -
      https://fontawesome.com/license (Commercial License) -->
      <path
        fill="currentColor"
        d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17
      0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    shouldScroll: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  mounted() {
  },
  methods: {
    scrollDown() {
      if (this.shouldScroll) {
        window.scroll({
          top: window.innerHeight,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-indicator {
  z-index: 11;
  &:hover {
    cursor: pointer;
  }
}
</style>
