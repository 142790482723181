<template>
  <div>
    <div
      class="intro-container row-24 m-0 justify-center items-center"
      :style="backgroundImgUrl ? {backgroundImage: `url(${backgroundImgUrl})`} : null"
      @click="scrollDown"
    >
      <h1 class="intro-title text-white col-12" v-html="payload.introtext" />
      <ScrollIndicator />
    </div>
    <section class="work-section bg-white row-24 m-0">
      <div
        class="work-container flex flex-wrap row-24 col-22 offset-1 md:col-16 md:offset-4 lg:offset-4 m-0"
      >
        <Go
          v-for="project in projects"
          :key="project.id"
          :to="project.link"
          class="work-card col-24 sm:col-12 md:col-12 lg:col-8 m-0 flex flex-col justify-center items-center"
          @mousemove="getMousePosition"
        >
          <h2 v-html="project.projekttitel" />
          <h3
            v-for="category in project.atworkkategorie"
            :key="category.id"
          >
            {{ category.title }}
          </h3>
          <div class="work-teaser" :style="{ top: mouseY + 'px', left: mouseX + 'px' }">
            <img
              :src="project.teaserbild.large"
              :alt="project.teaserbild.alt"
            >
          </div>
        </Go>
      </div>
    </section>
  </div>
</template>

<script>

import ScrollIndicator from '../parts/ScrollIndicator.vue';

export default {
  components: {
    ScrollIndicator,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      projects: this.payload.projects,
      backgroundImgUrl: this.payload.introhintergrund.large,
      mouseX: 0,
      mouseY: 0,
    };
  },
  methods: {
    getMousePosition(event) {
      const card = event.target.getBoundingClientRect();
      const x = event.clientX - card.left;
      const y = event.clientY - card.top;
      this.mouseX = x;
      this.mouseY = y;
    },
    scrollDown() {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.work {
  &-section {
    padding-top: 0;
    @screen lg {
      padding-top: 8rem;
    }
  }
  &-container {
    padding: 4rem 0;
  }
}

</style>
